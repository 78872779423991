import { Typography, Grid, Card, CardMedia, CardContent, Button, Checkbox, Radio, FormControlLabel, Box, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ClonRVideo, { VideoGroup } from '../../shared/src/models/clonR/clonRVideo';
import VideoGroupPlayerCtrl from '../clonR/videoGroupPlayerCtrl';

function SettingsPage() {

    const [startPage, setStartPage] = useState("settings");

    useEffect(() => {
        const _startPage = localStorage.getItem('startPage');
        if (_startPage) setStartPage(_startPage);
    }, []);

    const tiles = [
        {
            id: "clonr",
            title: "ClonR",
            description: "Erstelle Sie Ihren virtuellen AI Videoklon.",
            image: "images/clonrthumb.jpg",
            linkTitle: "Starten",
            link: "/clonr",
            showStartPageOption: true,
            videoGroup: {
                id: "clonRVideoGroup",
                videoGroupId: "clonRVideoGroup",
                name: "clonRVideoGroup",
                videos: createClonRVideos("clonR", "images/clonrthumb.jpg", "videos/clonr/", ["de", "en", "fr", "es", "zh"]),
                latestVideoDate: new Date()

            } as VideoGroup
        },
        {
            id: "calendr",
            title: "CalendR",
            description: "Smarter AI Praxiskalender.",
            image: "images/calendrthumb.jpg",
            linkTitle: "Starten",
            link: "/calendr",
            showStartPageOption: true
        },
        {
            id: "todos",
            title: "Aufgaben",
            description: "Ihr Praxis voll im Griff.",
            image: "images/tasksthumb.jpg",
            linkTitle: "Starten",
            link: "/todos",
            showStartPageOption: true
        },
        {
            id: "signr",
            title: "SignR",
            description: "Digitale Aufklärung und Anamnese",
            image: "images/signrthumb.jpg",
            linkTitle: "Starten",
            link: "/settings/documents",
            showStartPageOption: false
        },
    ];

    const steps = [
        'Account erstellen',
        'Praxiskalender einrichten',
        'Besuchsgründe definieren'
    ];

    function createClonRVideos(name: string, thumbnail: string, path: string, languages: string[]) {
        return languages.map((language) => {
            return {
                ...new ClonRVideo(),
                name: name,
                thumbnailUrl: thumbnail,
                videoUrl: `${path}/${language}.mp4`,
                language,
                status: 'finished'
            } as ClonRVideo
        });

    }

    function handleStartPageClick(tileId: string) {
        setStartPage(tileId);
        localStorage.setItem('startPage', tileId);
    }

    return <div className="kt-page-content">
        <div
            style={{
                textAlign: "center",
            }}
        >


            <h1><img src="/images/logo.png" width={40} alt="PickADoc Logo" style={{ marginBottom: "-7px" }} /> Pick-A-Doc</h1>

            <h3>Entdecken Sie alle Funktionen der smartesten AI Tools für Ihre Praxis</h3>

            <Grid container spacing={3} justifyContent="center">
                {tiles.map((tile, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            }}
                        >
                            {tile.videoGroup ?

                                <VideoGroupPlayerCtrl 
                                    id="clonRVideoPlayer" 
                                    videoGroup={tile.videoGroup} 
                                    smallButtons={true} 
                                    forcePreloading={false}
                                />
                                :
                                <CardMedia
                                    component="img"
                                    height="270"
                                    image={tile.image}
                                    alt={tile.title}
                                />
                            }

                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {tile.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {tile.description}
                                </Typography>
                                <Button
                                    component={NavLink}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "1rem" }}
                                    to={tile.link}
                                >
                                    {tile.linkTitle}
                                </Button>

                                {tile.showStartPageOption && <FormControlLabel
                                    control={<Checkbox size='small' onClick={() => handleStartPageClick(tile.id)} checked={startPage === tile.id} />}
                                    label={<Typography variant="body2" color="textSecondary">Als Startseite festlegen</Typography>}
                                    style={{ marginTop: "1rem" }}
                                />}

                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    </div>;
}

export default SettingsPage;